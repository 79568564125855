export default {
	methods: {
		onActionClick (actionId) {
			if (!this[actionId] ||
				typeof this[actionId] !== 'function') {
				return;
			}
			this[actionId]();
		}
	}
};
