<template>
	<div data-component="select-field">
		<validation-provider
			v-slot="{ errors, required }"
			tag="div"
			:rules="rules"
			:vid="getVid"
			:name="getName"
		>
			<div
				:data-error="errors && errors[0]"
			>
				<label
					data-element="label"
					:for="getId"
					:data-required="required"
					v-html="getLabel"
					v-if="hasLabel"
				/>
				<multiselect
					:id="getId"
					:name="getName"
					:options="options"
					:showLabels="showLabels"
					v-model="val"
					:taggable="tags"
					:placeholder="getPlaceholder"
					:multiple="isMultiple"
					:allow-empty="emptyAllowed"
					:track-by="getValueAttr"
					:label="getLabelAttr"
					:searchable="searchable"
					:internal-search="internalSearch"
					:loading="searchIsLoading"
					:disabled="disabled"
					:selectLabel="selectLabel"
					:deselectLabel="deselectLabel"
					:hideSelected="hideSelected"
					:showNoOptions="showNoOptions"
					@input="onChange"
					@tag="onTag"
					@search-change="onSearchChange"
				>
					<template
						v-if="htmlOptions"
						#singleLabel="{ option }"
					>
						<span v-html="option[getLabelAttr]" />
					</template>
					<template
						v-if="htmlOptions"
						#option="{ option }"
					>
						<span v-html="option[getLabelAttr]" />
					</template>
					<template
						v-if="htmlOptions"
						#tag="{ option, remove }"
					>
						<span class="multiselect__tag">
							<span v-html="option[getLabelAttr]" />
							<i
								aria-hidden="true"
								tabindex="1"
								@keypress.enter.prevent="remove(option)"
								@mousedown.prevent="remove(option)"
								class="multiselect__tag-icon" />
						</span>
					</template>
				</multiselect>
			</div>
			<p
				v-if="errors[0]"
				data-element="error"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>
</template>

<script>

	import Multiselect    from 'vue-multiselect';
	import { paramCase }  from 'change-case';
	import { camelCase }  from 'change-case';

	export default {
		components: {
			Multiselect
		},
		props: {
			name: {
				type: String,
				required: true
			},
			id: {
				type: String,
				default: undefined
			},
			keys: {
				type: Array,
				default: () => ['text', 'value']
			},
			rules: {
				type: [
					String,
					Object
				],
				default: ''
			},
			options: {
				type: Array,
				default: () => []
			},
			showLabels: {
				type: Boolean,
				default: true
			},
			emptyAllowed: {
				type: Boolean,
				default: true
			},
			tags: {
				type: Boolean,
				default: false
			},
			hasLabel: {
				type: Boolean,
				default: true
			},
			value: {
				type: [String, Number, Array],
				default: ''
			},
			multiple: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: undefined
			},
			hasPlaceholder: {
				type: Boolean,
				default: true
			},
			searchable: {
				type: Boolean,
				default: false
			},
			internalSearch: {
				type: Boolean,
				default: false
			},
			searchIsLoading: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			htmlOptions: {
				type: Boolean,
				default: false
			},
			selectLabel: {
				type: String,
				default: undefined
			},
			deselectLabel: {
				type: String,
				default: undefined
			},
			hideSelected: {
				type: Boolean,
				default: false
			},
			showNoOptions: {
				type: Boolean,
				default: true
			}
		},
		data: () => ({
			val: ''
		}),
		computed: {
			getName () {
				return this.name;
			},
			getPlaceholder () {
				if (!this.hasPlaceholder) {
					return '';
				}
				if (this.placeholder) {
					return this.placeholder;
				}
				return !this.multiple ? 'Please select...' : '';
			},
			getLabel () {
				return this.name;
			},
			getLabelAttr () {
				return this.keys[0];
			},
			getValueAttr () {
				return this.keys[1];
			},
			getId () {
				if (!this.id) {
					return paramCase(this.getName);
				}
				return this.id;
			},
			getVid () {
				if (!this.id) {
					return camelCase(this.getName);
				}
				return this.id;
			},
			isMultiple () {
				return this.multiple;
			}
		},
		mounted () {
			if (!this.isMultiple) {
				this.val = this.options.find(option => option.value === this.value);
				return;
			}
			if (this.value !== null) {
				this.val = this.options.filter(option => this.value.includes(option[this.keys[1]]));
			}
		},
		methods: {
			onTag (newTag) {
				const tags = this.val;
				const tag = {
					text: newTag,
					value: newTag
				};
				this.options.push(tag);
				tags.push(tag);
				this.emitVals(tags);
			},
			emitVals (options) {
				const values = [];
				for (const opt of options) {
					values.push(opt[this.keys[1]]);
				}
				this.$emit('input', values);
			},
			onChange (option) {
				if (!this.val) {
					return;
				}
				if (!this.isMultiple) {
					this.$emit('input', option[this.keys[1]]);
					return;
				}
				this.emitVals(option);
			},
			onSearchChange (query) {
				this.$emit('searchChange', query);
			},
			setValue (val) {
				this.val = val;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='select-field'] {
		ul {
			padding: 0;
		}
		::v-deep .multiselect {
			// min-height: 32px;
			// border-radius: 0;
			// box-sizing: border-box;
			.multiselect__select {
				// height: 100%;
			}
			.multiselect__tags {
				// padding: 4px 40px 0 4px;
				// box-sizing: border-box;
				.multiselect__input,
				.multiselect__single,
				.multiselect__tags-wrap {
					// font-size: rem(12);
					@include rich-text-suits;
				}
			}
			.multiselect__content-wrapper {
				.multiselect__content {
					max-width: 100%;
					.multiselect__element {
						.multiselect__option {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							@include rich-text-suits;
						}
					}
				}
			}
		}
		[data-error] {
			[data-element="label"],
			::v-deep .multiselect__tags {
				color: $c-red;
				border-color: $c-red;
			}
			.multiselect__select:before {
				border-color: $c-red transparent transparent;
			}
		}
	}
</style>
